<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <v-card
      tile
      flat
      class="d-flex flex-column overflow-auto"
      width="99vw"
      max-width="1200"
      height="calc(89vh - 20px)"
    >
      <v-row justify="center" align="center" class="mb-3 ma-0 pa-0">
        <v-col
          :class="$vuetify.breakpoint.lg ? 'pt-10' : ''"
          cols="5"
          sm="5"
          md="5"
          lg="6"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-card flat class="d-flex flex-column justify-center align-center">
            <v-img
              width="550"
              max-width="550px"
              :src="photo"
              alt="Onboard concluded photo"
              class="mb-0"
            ></v-img>
          </v-card>
        </v-col>
        <v-col
          cols="7"
          sm="12"
          md="7"
          lg="6"
          :class="$vuetify.breakpoint.lg ? 'pt-10' : ''"
        >
          <v-card
            flat
            class="d-flex flex-column justify-center align-center mr-5"
          >
            <v-card-title
              class="primary--text text-h6 text-sm-h5 font-weight-bold text-center break-word text-wrap normal-line-height"
              style="max-width: 500px"
            >
              {{ $t("onboardingNewUser.finishOnboardingTitle") }}
            </v-card-title>

            <v-subheader
              class="text-none text-body-1 text-sm-h6 font-weight-medium text-center"
            >
              {{ $t("onboardingNewUser.finishOnboardingSubtitle") }}:
            </v-subheader>

            <v-list class="px-0">
              <v-list-item v-for="(item, index) in getStartedList" :key="index">
                <v-list-item-action class="mr-4">
                  <v-icon
                    v-text="'mdi-check-circle'"
                    size="26"
                    color="success"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap break-word normal-line-height text-body-1 secondary--text"
                  >
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="link-style"
                    @click="setOnboardingStep(2)"
                    >{{ item.subtitle }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <FeedbackRating
              class="scale"
              action="onboarding_user"
              :width="`${breakpoint}`"
              txtAreaHeight="90"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-spacer />
      <div>
        <v-btn
          height="52"
          color="accent"
          class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
          @click="$emit('goHome')"
          :loading="loading"
        >
          <v-icon right v-text="'mdi-home'" class="mr-4" size="22" />
          {{ $t("common.homePage") }}
        </v-btn>
      </div>
    </v-footer>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import FeedbackRating from "@/components/forms/FeedbackRating.vue";
export default {
  name: "ConcludedOnboarding",
  components: { FeedbackRating },

  data() {
    return {
      photo: require("@/assets/images/concluded-onboarding.svg"),
      loading: false,
    };
  },

  computed: {
    getStartedList() {
      return [
        {
          //TODO: remover quando tiver o envio automático das informações
          title: this.$t("onboardingNewUser.guidanceForSendingAccessToUser"),
          subtitle: this.$t("common.clickToCopyData"),
        },
        {
          title: this.$t("onboardingNewUser.rateExperience"),
        },
      ];
    },
    breakpoint() {
      if (this.$vuetify.breakpoint.mdAndDown) return 400;
      else return 600;
    },
  },
  methods: {
    ...mapMutations(["setOnboardingStep"]),
  },
};
</script>
<style scoped>
.link-style {
  text-decoration: underline;
  cursor: pointer;
}
</style>
