var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{staticClass:"ma-4 ms-1",attrs:{"flat":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{class:`${_vm.outlined ? 'outlined' : ''}`},[(_vm.showLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-expansion-panel-header',{staticClass:"grey--text text--darken-2 font-weight-medium text-body-1"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(_vm.$t("common.configSecondaryEmail"))+" "),_c('small',[_vm._v(_vm._s(_vm.$t("common.sendEmailAs")))])]),_c('v-spacer'),_c('v-play-video-button',{staticClass:"mr-2",attrs:{"title":_vm.$t('common.sendEmailAs'),"id_youtube_video":"ygPflAvriSA","justify":"end"}})],1),_c('v-expansion-panel-content',{class:`pb-0 ${_vm.overlayScopes ? 'pa-3' : ''}`,staticStyle:{"position":"relative"}},[_c('v-card-text',{staticClass:"mx-0 pt-0 px-0"},[_vm._v(" "+_vm._s(_vm.$t("common.configSecondaryEmailDescription"))+" ")]),_c('v-simple-table',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.email},[(item.mainUser == true && item.name == '')?_c('td',[_vm._v(" "+_vm._s(_vm.user.name)+" ("+_vm._s(item.email)+") ")]):(item.name != '')?_c('td',[_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.email)+") ")]):_c('td',[_vm._v(_vm._s(item.email))]),(item.default == true)?_c('td',{staticClass:"pl-8",attrs:{"align":"end"}},[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("common.defaultEmail")))])]):_c('td',{attrs:{"align":"end"}},[_c('v-btn',{staticClass:"end-text",attrs:{"text":"","small":"","outlined":"","color":"accent","dark":""},on:{"click":function($event){(_vm.showDialogMainSendAs = true),
                        ((_vm.email_to_update = item.email),
                        (_vm.current_signature = item.signature))}}},[_vm._v(_vm._s(_vm.$t("action.makeDefault")))]),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500","content-class":"elevation-0","overlay-opacity":"0.1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit(`close`)},"click:outside":function($event){return _vm.$emit(`close`)}},model:{value:(_vm.showDialogMainSendAs),callback:function ($$v) {_vm.showDialogMainSendAs=$$v},expression:"showDialogMainSendAs"}},[_c('v-card',[_c('h2',{staticClass:"title py-3 px-6"},[_vm._v(" "+_vm._s(_vm.$t("common.confirmChange"))+" ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t("common.confirmAction")))]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){_vm.showDialogMainSendAs = false}}},[_vm._v(_vm._s(_vm.$t("action.cancel")))]),_c('v-btn',{attrs:{"color":"#311F50","text":""},on:{"click":function($event){_vm.updateSendAs(_vm.email_to_update),
                              (_vm.showDialogMainSendAs = false)}}},[_vm._v(_vm._s(_vm.$t("action.confirm")))])],1)],1)],1)],1),(!item.mainUser)?_c('td',{attrs:{"align":"end"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","small":"","outlined":"","color":"accent","dark":""},on:{"click":function($event){(_vm.showDialogModifyItem = true),
                        ((_vm.email_to_modify = item.email),
                        (_vm.modify_email_is_default = item.default),
                        (_vm.current_signature = item.signature))}}},[_vm._v(_vm._s(_vm.$t("action.edit")))]),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500","content-class":"elevation-0","overlay-opacity":"0.1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit(`close`)},"click:outside":function($event){return _vm.$emit(`close`)}},model:{value:(_vm.showDialogModifyItem),callback:function ($$v) {_vm.showDialogModifyItem=$$v},expression:"showDialogModifyItem"}},[_c('v-card',[_c('h2',{staticClass:"title py-3 px-6"},[_vm._v(" "+_vm._s(_vm.$t("common.confirmModify"))+" ")]),_c('v-card-text',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t("common.enterNameFor"))+" "+_vm._s(_vm.email_to_modify)+". "),_c('br'),_vm._v(" Ex.: Comercial, Contato, Financeiro… "),_c('v-row',[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"py-0",attrs:{"label":_vm.$t('common.name'),"color":"#311F50"},model:{value:(_vm.newItem.name),callback:function ($$v) {_vm.$set(_vm.newItem, "name", $$v)},expression:"newItem.name"}})],1)],1),_vm._v(" "+_vm._s(_vm.$t("common.descriptionConfirmModification"))+" "+_vm._s(_vm.email_to_modify)+"? ")],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.showDialogModifyItem = false}}},[_vm._v(_vm._s(_vm.$t("action.cancel")))]),_c('v-btn',{attrs:{"color":"accent","text":"","disabled":!_vm.newItem.name},on:{"click":function($event){_vm.updateSendAs(
                              _vm.email_to_modify,
                              true,
                              _vm.modify_email_is_default
                            ),
                              (_vm.showDialogModifyItem = false)}}},[_vm._v(_vm._s(_vm.$t("action.confirm")))])],1)],1)],1)],1):_c('td',{attrs:{"align":"end"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","small":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("action.edit")))])],1),(!item.mainUser)?_c('td',{attrs:{"align":"end"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","small":"","outlined":"","color":"accent","dark":""},on:{"click":function($event){(_vm.showDialogDeleteItem = true),
                        (_vm.email_to_delete = item.email)}}},[_vm._v(_vm._s(_vm.$t("action.delete")))]),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500","content-class":"elevation-0","overlay-opacity":"0.1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit(`close`)},"click:outside":function($event){return _vm.$emit(`close`)}},model:{value:(_vm.showDialogDeleteItem),callback:function ($$v) {_vm.showDialogDeleteItem=$$v},expression:"showDialogDeleteItem"}},[_c('v-card',[_c('h2',{staticClass:"title py-3 px-6"},[_vm._v(" "+_vm._s(_vm.$t("common.confirmDeletion"))+" ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t("common.confirmDeletionDescription"))+" "+_vm._s(_vm.email_to_delete)+"?")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.showDialogDeleteItem = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#311F50","text":""},on:{"click":function($event){_vm.deleteSendAs(_vm.email_to_delete),
                              (_vm.showDialogDeleteItem = false)}}},[_vm._v(_vm._s(_vm.$t("action.confirm")))])],1)],1)],1)],1):_c('td',{attrs:{"align":"end"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","small":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("action.delete")))])],1)])}),0)]},proxy:true}])}),_c('v-tooltip',{attrs:{"disabled":_vm.userHasGmail,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"fit-content"}},'div',attrs,false),on),[_c('v-btn',{staticClass:"mt-0 elevation-0 text-none font-weight-medium text-body-2 white--text",attrs:{"color":`${_vm.isOnboardingPage ? 'primarySuite' : 'accent'}`,"disabled":_vm.showLoading || !_vm.userHasGmail},on:{"click":function($event){_vm.dialogAddUser = true}}},[_vm._v(" "+_vm._s(_vm.$t("common.addEmailAddress"))+" ")])],1)]}}])},[(!_vm.userHasGmail)?_c('span',[_vm._v(" O serviço de e-mail deste usuário não está ativo. ")]):_vm._e()]),_c('ApplySendAs',{attrs:{"show":_vm.dialogAddUser,"users":[_vm.user],"loading":_vm.showLoading},on:{"loading":function($event){_vm.showLoading = $event},"then":function($event){return _vm.thenCreateSendAs()},"close":function($event){return _vm.cancelBtn()}}}),(
            _vm.renewFeedbackOnSecondTime(_vm.currentUser.feedbacks.send_as_email) &&
            _vm.checkShowFeedback('send_as_email')
          )?_c('HeaderDialog',{ref:"form",attrs:{"id":"user_feedback","title":"Deixe seu feedback sobre a funcionalidade","show":_vm.showUserFeedback,"width":"600","persistent":false,"custom-footer":""},on:{"close":_vm.closeFeedback},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"mx-0"},[_c('FeedbackRating',{attrs:{"action":"send_as_email","showDismiss":""},on:{"close":_vm.closeFeedback}})],1)],1)]},proxy:true}],null,false,2666281089)}):_vm._e(),_c('OverlayScopes',{attrs:{"overlay":_vm.overlayScopes,"functionality":_vm.$t('common.configSecondaryEmail'),"dense":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }